@tailwind base;
@tailwind components;
@tailwind modules;
.lazy-load {
  @apply transition
        opacity-0;
}

.lazy-load--loaded {
  @apply opacity-100 
          delay-300;
}

.link {
  @apply transition;
}

.link--primary {
  @apply underline;
}

.blue-bg-gradient {
  background: linear-gradient(180deg, rgba(0, 101, 183, 0.6) 0%, rgba(0, 101, 183, 0.15) 100%);
}

.white-bg-gradient {
  background: rgba(243, 247, 251, 0.9);
}

.carousel-cell {
  display: flex;
  align-items: stretch;
  min-height: 100%;
}

.slideCarouselButton {
  @apply text-white;
}

.slideCarouselButton.is-selected {
  background-color: white;
  border-radius: 4px 4px 0px 0px;
  @apply text-secondary;
}

@media (max-width: 1200px) {
  .hideNavItemMoblile {
    display: none;
  }
}

.slide-ease {
  animation: fadeInAnimation ease 0.1s;
  animation-delay: 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.review-carousel .flickity-viewport {
  overflow: visible;
}

.disable-icon {
  opacity: 0.5;
}

.review-carousel {
  margin-right: 24px;
}

.carousel-cell {
  margin-right: 24px;
}

.button-rev {
  transform: rotate(90deg);
  margin-right: 5px;
}

.button-next {
  transform: rotate(-90deg);
}

.button-group {
  display: inline-block;
  vertical-align: bottom;
}

.button-group:after {
  content: "";
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  border-radius: 0;
  border-right-width: 0;
}

.button-group .button:first-child {
  border-radius: 6px 0 0 6px;
}

.button-group .button:last-child {
  border-radius: 0 6px 6px 0;
  border-right-width: 1px;
}

.button-group {
  display: inline-block;
  vertical-align: bottom;
}

.button-group:after {
  content: "";
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  border-radius: 0;
  border-right-width: 0;
}

.button-group .button:first-child {
  border-radius: 6px 0 0 6px;
}

.button-group .button:last-child {
  border-radius: 0 6px 6px 0;
  border-right-width: 1px;
}

.button-row {
  text-align: center;
}

@media only screen and (max-width: 639px) {
  form div button {
    width: 100% !important;
  }
}

form label {
  color: #003c6c !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}

form .fill-current {
  fill: #989faa !important;
}

form button {
  background-color: #f7e04b !important;
  color: #003c6c !important;
  width: 150px !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

[name="form_previous_page_button"] {
  background-color: unset !important;
  color: #003c6c !important;
  width: 150px !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  outline: #003c6c;
  border-color: #003c6c;
  border-width: 2px;
}

@media only screen and (max-width: 639px) {
  form div button {
    width: 100% !important;
  }
}

.service-block-icon-fill {
  fill: white;
}

.service-block-icon:hover * {
  fill: #f7e04b !important;
  color: #f7e04b;
}

@media only screen and (max-width: 639px) {
  form div button {
    width: 100% !important;
  }
}

.register-form ul {
  display: none !important;
}

.modalCatchment {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
}

.catchment-outline {
  height: 420px;
  width: 100%;
  border: 2px solid #eee;
  display: inline-block;
  overflow: hidden;
}

@media only screen and (max-width: 420px) {
  .catchment-outline {
    height: 300px;
  }
}

.catchment-iframe {
  position: relative;
  top: -65px;
  border: none;
}

.modalCatchment-content {
  background-color: #fefefe;
  margin: auto;
  border: 1px solid #888;
}

.closeModal {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.closeModal:hover,
.closeModal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.search-form input {
  width: 0px;
  margin-left: 0px;
  margin-right: 0px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s ease, opacity 1s ease, margin-left 1s ease, width 1s ease;
}

.search-form:hover input {
  width: 200px;
  visibility: visible;
  opacity: 1;
  margin-left: 15px;
}

.bg-light-blue {
  background-color: #f3f7fb;
}

.ul-reset ul {
  list-style-type: disc !important;
  margin-left: 20px !important;
  padding-bottom: 10px;
}

.ul-reset li {
  display: list-item !important;
}

.contact-modals svg {
  height: 80%;
  width: auto;
}

.line-h05 {
  line-height: 0.5 !important;
}

.b-line-spacer {
  border-bottom: 2px solid #000000;
  opacity: 10%;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}

.gmap iframe {
  width: 740px !important;
  height: 326px !important;
}

@media only screen and (max-width: 1023px) {
  .gmap {
    width: 100% !important;
  }
  .gmap iframe {
    width: 100% !important;
  }
}

.modal-tabs .tablinks {
  height: 90px;
}

@media only screen and (max-width: 1023px) {
  .modal-tabs .grid-lock {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

.modal-tabs .active {
  height: 100px;
}

.modal-tabs .active {
  background-color: #f3f7fb !important;
}

.modal-tabs .location-tab:hover > .text-white,
.modal-tabs .active > .text-white {
  color: #003c6c !important;
}

.modal-tabs .location-tab:hover,
.modal-tabs .tab-arrow .active .tab-arrow {
  fill: #003c6c !important;
}

.modal-tabs .tab-arrow {
  display: none;
}

.modal-tabs .contact-blocks {
  display: none !important;
}

@media only screen and (min-width: 1024px) {
  .modal-tabs .tablinks {
    height: 170px;
  }
  .modal-tabs .tablinks:hover,
  .modal-tabs .active {
    height: 200px;
  }
  .modal-tabs .contact-blocks {
    display: block !important;
  }
  .modal-tabs .tab-arrow {
    fill: #f7e04b;
    display: block;
  }
}

.overflow-x {
  overflow-x: hidden;
}

.disable-icon {
  opacity: 0.5;
}

.overlay-image {
  filter: brightness(0.7);
}

.image-hw {
  height: 525px !important;
  width: 343px !important;
}

.services-carousel .flickity-viewport {
  overflow: visible !important;
}

.services-carousel {
  margin-right: 24px;
}

.carousel-cell {
  margin-right: 24px;
}

/* ---- buttons ---- */
.button-rev {
  transform: rotate(90deg);
  margin-right: 5px;
}

.button-next {
  transform: rotate(-90deg);
}

.button-group {
  display: inline-block;
  vertical-align: bottom;
}

.button-group:after {
  content: "";
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  border-radius: 0;
  border-right-width: 0;
}

.button-group .button:first-child {
  border-radius: 6px 0 0 6px;
}

.button-group .button:last-child {
  border-radius: 0 6px 6px 0;
  border-right-width: 1px;
}

/* ---- button-row ---- */
.button-row {
  text-align: center;
}

.nav-image {
  object-fit: contain !important;
}

/* HAMBURGER MENU */
.hamburger {
  cursor: pointer;
  width: 48px;
  height: 48px;
  transition: all 0.25s;
}

.hamburger__top-bun,
.hamburger__bottom-bun {
  content: "";
  position: absolute;
  width: 24px;
  height: 2px;
  background: #000;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger:hover [class*="-bun"] {
  background: #003c6c;
}

.hamburger__top-bun {
  transform: translateY(-5px);
}

.hamburger__bottom-bun {
  transform: translateY(3px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(-1px);
}

.open .hamburger__top-bun {
  transform: rotate(45deg) translateY(0px);
}

.open .hamburger__bottom-bun {
  transform: rotate(-45deg) translateY(0px);
}

form label {
  color: #003c6c !important;
  text-transform: capitalize !important;
}

form .fill-current {
  fill: #989faa !important;
}

form button {
  background-color: #f7e04b !important;
  color: #003c6c !important;
  width: 150px !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.service-block-icon-fill {
  fill: white;
}

.service-block-icon:hover * {
  fill: #f7e04b !important;
  color: #f7e04b;
}

.modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;
  margin: auto;
  padding: 0;
  border: 1px solid #888;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

#div2,
#div3 {
  display: none;
}

#prev {
  display: none;
  float: left;
}

.clear {
  clear: both;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  display: inline-block;
  padding: 10px;
}

.active {
  font-weight: bold;
}

.span {
  display: inline-block;
  width: 30px;
  line-height: 28px;
  border-radius: 20px;
  background-color: #a3248f;
  color: white;
  text-align: center;
  margin-right: 10px;
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-Bold.eot");
  src: local("Aeonik"), url("../fonts/Aeonik-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Bold.woff2") format("woff2"), url("../fonts/Aeonik-Bold.woff") format("woff"), url("../fonts/Aeonik-Bold.ttf") format("truetype"), url("../fonts/Aeonik-Bold.svg#Aeonik-Bold") format("svg");
  font-weight: 700;
  font-style: "bold";
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-Medium.eot");
  src: local("Aeonik"), url("../fonts/Aeonik-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Medium.woff2") format("woff2"), url("../fonts/Aeonik-Medium.woff") format("woff"), url("../fonts/Aeonik-Medium.ttf") format("truetype"), url("../fonts/Aeonik-Medium.svg#Aeonik-Medium") format("svg");
  font-weight: 500;
  font-style: "medium";
  font-display: swap;
}

@font-face {
  font-family: "Aeonik";
  src: url("../fonts/Aeonik-Regular.eot");
  src: local("Aeonik"), url("../fonts/Aeonik-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/Aeonik-Regular.woff2") format("woff2"), url("../fonts/Aeonik-Regular.woff") format("woff"), url("../fonts/Aeonik-Regular.ttf") format("truetype"), url("../fonts/Aeonik-Regular.svg#Aeonik-Regular") format("svg");
  font-weight: 400;
  font-style: "normal";
  font-display: swap;
}

html {
  @apply text-base;
}

body {
  @apply font-sans-primary
     text-optimize-legibility
     antialiased;
}

h1 {
  @apply text-4bxl
      font-sans-primary
      font-bold;
}

a {
  @apply cursor-pointer
   underline
   hover:opacity-50
   transition
   duration-500;
}

a.buttonNoUnderline, a.linkNoUnderline {
  text-decoration: none;
}

a.noHoverOpacity {
  @apply hover:opacity-100;
}

p {
  @apply mb-4;
}

p:last-of-type {
  @apply mb-0;
}

p:empty {
  @apply hidden;
}

.wf-loading {
  @apply invisible;
}

.wf-active,
.wf-inactive {
  @apply visible;
}

h6 {
  font-size: 0.75rem;
  line-height: 1.5;
}

.carousel .button,
.carouselNav .button {
  border: unset !important;
  background-color: unset !important;
}

.carousel .is-selected,
.carouselNav .is-selected {
  background-color: white !important;
}

button {
  border: unset !important;
  background-color: unset !important;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

@tailwind utilities;
