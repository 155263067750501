.modalCatchment {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999;
  @media only screen and (max-width: 600px) {
  }
}
.catchment-outline {
  height: 420px;
  width: 100%;
  border: 2px solid #eee;
  display: inline-block;
  overflow: hidden;
  @media only screen and (max-width: 420px) {
    height: 300px;
  }
}

.catchment-iframe {
  position: relative;
  top: -65px;
  border: none;
}
// Modal Content
.modalCatchment-content {
  background-color: #fefefe;
  margin: auto;

  border: 1px solid #888;
}

// The Close Button
.closeModal {
  color: #aaaaaa;
  float: right;

  font-size: 28px;
  font-weight: bold;
}

.closeModal:hover,
.closeModal:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
