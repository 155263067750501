.ul-reset {
  ul {
    list-style-type: disc !important;
    margin-left: 20px !important;
    padding-bottom: 10px;
  }
  li {
    display: list-item !important;
  }
}
