.search-form input {
  width: 0px;
  margin-left: 0px;
  margin-right: 0px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s ease, opacity 1s ease, margin-left 1s ease,
    width 1s ease;
}
.search-form:hover input {
  width: 200px;
  visibility: visible;
  opacity: 1;
  margin-left: 15px;
}
