.contact-modals svg {
  height: 80%;
  width: auto;
}

.line-h05 {
  line-height: 0.5 !important;
}
.b-line-spacer {
  border-bottom: 2px solid #000000;
  opacity: 10%;
  margin-left: -2.5rem;
  margin-right: -2.5rem;
}
.gmap {
  iframe {
    width: 740px !important;
    height: 326px !important;
  }
  @media only screen and (max-width: 1023px) {
    width: 100% !important;
    iframe {
      width: 100% !important;
    }
  }
}
.modal-tabs {
  .tablinks {
    height: 90px;
  }
  @media only screen and (max-width: 1023px) {
    .grid-lock {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }

  .active {
    height: 100px;
  }
  .active {
    background-color: #f3f7fb !important;
  }
  .location-tab:hover > .text-white,
  .active > .text-white {
    color: #003c6c !important;
  }

  .location-tab:hover,
  .tab-arrow .active .tab-arrow {
    fill: #003c6c !important;
  }
  .tab-arrow {
    display: none;
  }
  .contact-blocks {
    display: none !important;
  }
  @media only screen and (min-width: 1024px) {
    .tablinks {
      height: 170px;
    }
    .tablinks:hover,
    .active {
      height: 200px;
    }
    .contact-blocks {
      display: block !important;
    }
    .tab-arrow {
      fill: #f7e04b;
      display: block;
    }
  }
}
