.overflow-x {
  overflow-x: hidden;
}
.disable-icon {
  opacity: 0.5;
}
.overlay-image {
  filter: brightness(0.7);
}
.image-hw {
  height: 525px !important;
  width: 343px !important;
}
.services-carousel .flickity-viewport {
  overflow: visible !important;
}
.services-carousel {
  margin-right: 24px;
}
.carousel-cell {
  margin-right: 24px;
}

/* ---- buttons ---- */
.button-rev {
  transform: rotate(90deg);
  margin-right: 5px;
}

.button-next {
  transform: rotate(-90deg);
}

.button-group {
  display: inline-block;
  vertical-align: bottom;
}

.button-group:after {
  content: "";
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  border-radius: 0;
  border-right-width: 0;
}

.button-group .button:first-child {
  border-radius: 6px 0 0 6px;
}

.button-group .button:last-child {
  border-radius: 0 6px 6px 0;
  border-right-width: 1px;
}

/* ---- button-row ---- */

.button-row {
  text-align: center;
}
