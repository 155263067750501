.carousel-cell {
  display: flex;
  align-items: stretch;
  min-height: 100%;
}

.slideCarouselButton {
  &.is-selected {
    background-color: white;
    border-radius: 4px 4px 0px 0px;
    @apply text-secondary;
  }
  @apply text-white;
}
@media (max-width: 1200px) {
  .hideNavItemMoblile {
    display: none;
  }
}
.slide-ease {
  animation: fadeInAnimation ease 0.1s;
  animation-delay: 0.8s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.review-carousel .flickity-viewport {
  overflow: visible;
}
.disable-icon {
  opacity: 0.5;
}
.review-carousel {
  margin-right: 24px;
}
.carousel-cell {
  margin-right: 24px;
}

// carousel buttons
.button-rev {
  transform: rotate(90deg);
  margin-right: 5px;
}

.button-next {
  transform: rotate(-90deg);
}

.button-group {
  display: inline-block;
  vertical-align: bottom;
}

.button-group:after {
  content: "";
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  border-radius: 0;
  border-right-width: 0;
}

.button-group .button:first-child {
  border-radius: 6px 0 0 6px;
}

.button-group .button:last-child {
  border-radius: 0 6px 6px 0;
  border-right-width: 1px;
}

// button-group

.button-group {
  display: inline-block;
  vertical-align: bottom;
}

.button-group:after {
  content: "";
  display: block;
  clear: both;
}

.button-group .button {
  float: left;
  border-radius: 0;
  border-right-width: 0;
}

.button-group .button:first-child {
  border-radius: 6px 0 0 6px;
}

.button-group .button:last-child {
  border-radius: 0 6px 6px 0;
  border-right-width: 1px;
}

// button-row

.button-row {
  text-align: center;
}
