@tailwind base;
@tailwind components;
@tailwind modules;

@import "utilities/rem";
@import "utilities/font-face";

@import "components/lazy-load";
@import "components/links";
@import "components/bg-gradients";
@import "components/slide-carousel";
@import "components/forms";
@import "components/catchment-area.scss";
@import "components/search.scss";

@import "modules/hero";
@import "modules/paragraph.scss";
@import "modules/contact-details";
@import "modules/services-carousel";
@import "modules/top-nav";
@import "modules/service-modals.scss";

@import "base";

@tailwind utilities;
