// Font Face
@include font-face("Aeonik", "Aeonik-Bold", 700, "bold");
@include font-face("Aeonik", "Aeonik-Medium", 500, "medium");
@include font-face("Aeonik", "Aeonik-Regular", 400, "normal");

// Body

html {
  @apply text-base;
}

body {
  @apply font-sans-primary
     text-optimize-legibility
     antialiased;
}

h1 {
  @apply text-4bxl
      font-sans-primary
      font-bold;
}

// Links

a {
  @apply cursor-pointer
   underline
   hover:opacity-50
   transition
   duration-500;

  &.buttonNoUnderline,
  &.linkNoUnderline {
    text-decoration: none;
  }
  &.noHoverOpacity {
    @apply hover:opacity-100;
  }
}

// Typography

p {
  @apply mb-4;
  &:last-of-type {
    @apply mb-0;
  }
  &:empty {
    @apply hidden;
  }
}

// Selection

::selection {
}

// FOUT fix

.wf-loading {
  @apply invisible;
}

.wf-active,
.wf-inactive {
  @apply visible;
}

h6 {
  font-size: 0.75rem;
  line-height: 1.5;
}

.carousel,
.carouselNav {
  .button {
    border: unset !important;
    background-color: unset !important;
  }
  .is-selected {
    background-color: white !important;
  }
}
button {
  border: unset !important;
  background-color: unset !important;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}
