form div button {
  @media only screen and (max-width: 639px) {
    width: 100% !important;
  }
}

form label {
  color: #003c6c !important;
  text-transform: capitalize !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
}
form .fill-current {
  fill: #989faa !important;
}

form button {
  background-color: #f7e04b !important;
  color: #003c6c !important;
  width: 150px !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

[name="form_previous_page_button"] {
  background-color: unset !important;
  color: #003c6c !important;
  width: 150px !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
  outline: #003c6c;
  border-color: #003c6c;
  border-width: 2px;
}

form div button {
  @media only screen and (max-width: 639px) {
    width: 100% !important;
  }
}

.service-block-icon-fill {
  fill: white;
}

.service-block-icon:hover * {
  fill: #f7e04b !important;
  color: #f7e04b;
}

form div button {
  @media only screen and (max-width: 639px) {
    width: 100% !important;
  }
}

.register-form ul {
  display: none !important;
}
