form label {
  color: #003c6c !important;
  text-transform: capitalize !important;
}
form .fill-current {
  fill: #989faa !important;
}

form button {
  background-color: #f7e04b !important;
  color: #003c6c !important;
  width: 150px !important;
  border-radius: 30px !important;
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}
.modal-btn {
}
.service-block-icon-fill {
  fill: white;
}
.service-block-icon:hover * {
  fill: #f7e04b !important;
  color: #f7e04b;
}

.modal {
  display: none;
  position: fixed;
  z-index: 99;
  // padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  position: relative;

  margin: auto;
  padding: 0;
  border: 1px solid #888;
  // width: 80%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
}

@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.close {
  color: white;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.modal-header {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

.modal-footer {
  padding: 2px 16px;
  background-color: #5cb85c;
  color: white;
}

// test
#div2,
#div3 {
  display: none;
}

#prev {
  display: none;
  float: left;
}

.clear {
  clear: both;
}

ul {
  padding: 0;
  margin: 0;
}

li {
  display: inline-block;
  padding: 10px;
}

.active {
  font-weight: bold;
}

.span {
  display: inline-block;
  width: 30px;
  line-height: 28px;
  border-radius: 20px;
  background-color: #a3248f;
  color: white;
  text-align: center;
  margin-right: 10px;
}
